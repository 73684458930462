/* Lớp phủ chiếm toàn bộ màn hình */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Lớp nền mờ */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 44;
    /* Đảm bảo lớp phủ nằm trên cùng */
}

/* Container cho popup (nút) */
.popup-container {
    background-color: white;
    padding: 20px 40px;
    border-radius: 10px;
    text-align: center;
    position: relative;
}

/* Nút Connect */
.connect-button {
    background-color: rgb(46 179 53);
    /* Màu vàng của MetaMask */
    color: black;
    border: none;
    padding: 10px 18px;
    font-size: 18px;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 250px;
    margin-bottom: 10px;
}

.connect-button:hover {
    background-color: rgb(26, 216, 35);
    /* Màu khi hover */
}

.btn_local {
    --bs-btn-padding-x: 1rem;
    --bs-btn-padding-y: .75rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.4;
    --bs-btn-color: #c62a88;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 2px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 999px;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
    --bs-btn-disabled-opacity: .65;
    --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: mediumvioletred;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}